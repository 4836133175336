
<template>
  <div id="task-app" class="rounded relative overflow-hidden">
    <menu-update 
      :isUpdateActive="isUpdateActive"
      @closeUpdate="closeUpdateSidebar"
      @closeFilter="closeFilterSidebar"
      :data="dataTask">
    </menu-update>
    
    <vs-sidebar 
    class="items-no-padding" 
    parent="#task-app" 
    :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isSidebarFilterActive">
      <component :is="scrollbarTag" class="task-scroll-area" :settings="settings" :key="$vs.rtl">
        <menu-filters 
        @closeSidebar="toggleFilterSidebar"
        @openUpdate="addUpdateSidebar"
        :menuFilter="menuFilter"
        :Search="search">
        </menu-filters>
      </component>
    </vs-sidebar>
    
    <div :class="{'sidebar-spacer': clickNotClose}"  id="menu-load" class="no-scroll-content no-scroll-content vs-con-loading__container px-4">

      <div class="flex items-center py-1 mb-4">
        
        <feather-icon class="md:inline-flex lg:hidden cursor-pointer" icon="MenuIcon" @click.stop="toggleFilterSidebar(true)" />
        
        <vx-input-group class="w-full mr-4">
          <vs-input icon-no-border size="large" placeholder="Kata kunci..." v-model="search" v-on:keyup.enter="filterData()" class="shadow-md vs-input-no-border w-full border-radius" />

          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button color="warning" icon-pack="feather" icon="icon-search" @click="filterData()">Cari</vs-button>
            </div>
          </template>
        </vx-input-group>

        <div class="dropdown-button-container">
          <vs-button class="btnx" type="border" size="" color="success">Page</vs-button>

          <vs-dropdown>
            <vs-button class="btn-drop py-4" type="filled" icon="expand_more" size="" color="success"></vs-button>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="p in menuPage" :key="p" @click.stop="nextData(p)" :disabled="p === menuCurrentPage">
                {{ p }}
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
          
      </div>
      
      <component :is="scrollbarTag" id="menu-load" class="task-content-scroll-area" :settings="settings" ref="taskListPS" :key="$vs.rtl">

        <transition-group name="list-enter-up" tag="ul" appear>
          <li
            class="cursor-pointer"
            v-for="(task, index) in taskList"
            :key="String(menuFilter) + String(task.id)"
            :style="[{transitionDelay: (index * 0.1) + 's'}]"
            @click="viewUpdateSidebar(task)">
            <menu-item :data="task" />
          </li>
        </transition-group>
      </component>
      
    </div>
  </div>
</template>

<script>
import moduleMenu          from '@/stores/task/moduleTask.js'
import moduleKategori          from '@/stores/master/kategori/moduleKategori.js'
import MenuItem            from './MenuItem.vue'
import MenuFilters         from './MenuFilters.vue'
import MenuUpdate          from './MenuUpdate.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    MenuItem,
    MenuFilters,
    MenuUpdate,
    VuePerfectScrollbar
  },
  data () {
    return {
      dataTask             : {},
      currFilter           : '',
      clickNotClose        : true,
      isSidebarFilterActive: true,
      isUpdateActive       : false,
      settings : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30
      },
      // Param
      search: '',
    }
  },
  watch: {
    isUpdateActive (value) {
      if (!value) this.showThread = false
    },
    windowWidth () {
      this.setSidebarWidth()
    },
    menuFilter () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#menu-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.taskListPS.$el || this.$refs.taskListPS
      scroll_el.scrollTop = 0
      this.$store.commit('menu/UPDATE_MENU_FILTER', this.$route.params.filter)
      const obj = {
        sub_type: 'menu',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        search: this.search
      }
      this.$store.commit('menu/TASK_SET_NULL')
      this.$store.dispatch('menu/fetchTask', obj)
      if (this.$store.state.menu.tasks.length > 0 || this.$store.state.menu.tasks.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#menu-load > .con-vs-loading')
        }, 2000)
      }
      this.toggleFilterSidebar()
    }
  },
  computed: {
    menuFilter ()   { return this.$route.params.filter },
    taskList ()     { return this.$store.state.menu.tasks },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    windowWidth ()  { return this.$store.state.windowWidth },
    menuTotal () {
      return this.$store.state.menu.tasks_total
    },
    menuTotalSatu () {
      return this.$store.state.menu.tasks_total + 1
    },
    menuLast () {
      return this.$store.state.menu.tasks_last
    },
    menuPerPage () {
      return this.$store.state.menu.tasks_per_page
    },
    menuCurrentPage () {
      return this.$store.state.menu.tasks_current_page
    },
    menuPage () {
      const page = []
      const number = this.$store.state.menu.tasks_total / this.$store.state.menu.tasks_per_page
      var i
      for (i=0; i < number; i++) {
        page.push(i+1)
      }
      return page
    },
    menuTotalPage () {
      let number = this.$store.state.menu.tasks_total / this.$store.state.menu.tasks_per_page
      if (number < 1) {
        number = 1
      }
      return number.toFixed(0)
    }
  },
  created () {
    this.$vs.loading()
    this.$store.registerModule('menu', moduleMenu)
    this.$store.registerModule('kategori', moduleKategori)
    this.setSidebarWidth()
    const obj = {filter:'all', type:'menu', urutan:'desc', search:''}
    this.$store.dispatch('menu/taskPage', obj)
    this.$store.dispatch('kategori/kategoriFetch', 'kategori_menu')
    setTimeout(() => { this.$vs.loading.close() }, 2000)
  },
  beforeUpdate () {
    this.currFilter = this.$route.params.filter
  },
  beforeDestroy () {
    this.$store.unregisterModule('menu')
    this.$store.unregisterModule('kategori')
  },
  mounted () { 
  },
  methods: {
    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.isSidebarFilterActive = this.clickNotClose = false
      } else {
        this.isSidebarFilterActive = this.clickNotClose = true
      }
    },
    toggleFilterSidebar (value = false) {
      if (!value && this.clickNotClose) return
      this.isSidebarFilterActive = value
    },
    closeFilterSidebar () {
      this.isSidebarFilterActive = false
    },
    addUpdateSidebar() {
      this.dataTask = {}
      this.isUpdateActive = true
    },
    viewUpdateSidebar(data) {
      this.dataTask = data
      this.isUpdateActive = true
    },
    closeUpdateSidebar () {
      this.isUpdateActive = false
    },
    nextData (page) {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#menu-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.taskListPS.$el || this.$refs.taskListPS
      scroll_el.scrollTop = 0

      this.$store.commit('menu/UPDATE_MENU_FILTER', this.$route.params.filter)
      const obj = {
        sub_type: 'menu',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        search: this.search,
        page: page
      }
      this.$store.commit('menu/SET_NULL_TASKS')
      this.$store.dispatch('menu/fetchTask', obj)
      if (this.$store.state.menu.tasks.length > 0 || this.$store.state.menu.tasks.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#menu-load > .con-vs-loading')
        }, 2000)
      }
    },
    filterData () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#menu-load',
        type: 'sound',
        scale: 0.5
      })
      const scroll_el = this.$refs.taskListPS.$el || this.$refs.taskListPS
      scroll_el.scrollTop = 0

      this.$store.commit('menu/UPDATE_MENU_FILTER', this.$route.params.filter)
      const obj = {
        sub_type: 'menu',
        filter_type: this.$route.params.filter_type,
        filter: this.$route.params.filter,
        search: this.search
      }

      this.$store.commit('menu/SET_NULL_TASKS')
      this.$store.dispatch('menu/fetchTask', obj)
      if (this.$store.state.menu.tasks.length > 0 || this.$store.state.menu.tasks.length === 0) {
        setTimeout(() => {
          this.$vs.loading.close('#menu-load > .con-vs-loading')
        }, 2000)
      }
    }
  }
}

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/task.scss";
.dropdown-button-container {
  display: flex;
  align-items: center;

  .btnx {
    border-radius: 5px 0px 0px 5px;
  }

  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, .2);
  }
}
.total-slider {
  min-width: unset;
}
</style>
