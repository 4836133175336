
import state from './moduleKategoriState.js'
import mutations from './moduleKategoriMutations.js'
import actions from './moduleKategoriActions.js'
import getters from './moduleKategoriGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
