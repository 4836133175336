

<template>
  <div class="px-4 py-3 list-item-component shadow-md">
    <div class="vx-row md:px-4">
      <div class="vx-col md:w-3/4 w-1/2 items-center">
        <div class="flex items-center mb-1">
          <span class="text-primary mr-2">#{{data.nomor}}</span>
          <span class="">{{data.tanggal}}</span>
        </div>
        <div class="flex items-center">
          <h5 class="task-title items-center mb-1">
            {{ data.task_title }}
          </h5>
          <div class="task-tags ml-2 mt-1 flex">
            <vs-chip transparent v-for="(tag, index) in data.tags" :key="index" :color="menuLabelColor(tag)">
              {{ menuLabelText(tag) }}
            </vs-chip>
          </div>
        </div>
        <div class="flex items-center">
          <span>{{data.memo}}</span>
        </div>
      </div>

      <div class="vx-col md:w-1/4 w-1/2 flex items-center justify-end">
        <div class="flex items-center justify-end">
          <h5>{{formatTotal(data.task_total)}}</h5>
          <p class="truncate md:pl-6 pl-4">B.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      //data: this.$store.getters['menu/getTask'](this.taskId)
    }
  },
  computed: {
    menuLabelText () {
      return (text) => {
        const tags = this.$store.state.menu.taskTags
        return tags.find((tag) => {
          return tag.slug === text
        }).name
      }
    },
    menuLabelColor () {
      return (label) => {
        const tags = this.$store.state.menu.taskTags
        return tags.find((tag) => {
          return tag.slug === label
        }).color
      }
    }
  },
  methods: {
    persen(val) {
      return parseInt(val)
    },
    formatTotal(value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  }
}
</script>
