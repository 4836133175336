
export default {
  tasks: [],
  layanans: [],
  tasks_total: null,
  tasks_last: null,
  tasks_per_page: null,
  tasks_current_page: null,
  task: {}
}
