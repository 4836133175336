
export default {

  // KATEGORI
    KATEGORI_SET (state, items) {
      state.kategori = items
    },
    KATEGORI_ADD (state, item) {
      state.kategori.push(item)
    },
    KATEGORI_UPDATE (state, item) {
      const index = state.kategori.findIndex((p) => p.id === item.id)
      Object.assign(state.kategori[index], item)
    },
    KATEGORI_REMOVE (state, id) {
      const Index = state.kategori.findIndex((p) => p.id === id)
      state.kategori.splice(Index, 1)
    },

}
