
<template>
  <div class="menu__filters-container px-6">

    <vs-button class="bg-primary my-base w-full" icon-pack="feather" icon="icon-plus" @click="addUpdate()">Menu</vs-button>
    
    <router-link tag="span" class="flex cursor-pointer" :class="{'text-primary': menuFilter == 'all'}" :to="`${baseUrl}/filter/all`">
        <feather-icon icon="LayersIcon" :svgClasses="[{'text-primary stroke-current': menuFilter == 'all'}, 'h-6 w-6']"></feather-icon>
        <span class="text-lg ml-3">All</span>
    </router-link>
      
    <vs-divider class=""></vs-divider>
    
    <router-link tag="span" class="flex cursor-pointer" :class="{'text-primary': menuFilter == 'trash'}" :to="`${baseUrl}/filter/trash`">
        <feather-icon icon="TrashIcon" :svgClasses="[{'text-primary stroke-current': menuFilter == 'trash'}, 'h-6 w-6']"></feather-icon>
        <span class="text-lg ml-3">Trash</span>
    </router-link>
      
    <vs-divider class="mb-base"></vs-divider>
    
    <div class="flex mb-6 items-center flex-wrap justify-between">
      <div class="flex items-center">
        <h5>Kategori</h5>
      </div>
      <div class="flex">
        <feather-icon icon="PlusIcon" class="cursor-pointer ml-5" svg-classes="h-6 w-6" @click="activePromptKategori = true" />
      </div>
    </div>

    <div class="flex justify-between flex-wrap items-center" v-for="(kategori, index) in menuKategori" :key="index">
      <router-link tag="span" class="menu__label flex items-center cursor-pointer" :to="`${baseUrl}/${kategori.slug}`">
        <span class="text-md" :class="{'text-primary': menuFilter == kategori.slug}">
        {{ kategori.name }}
        </span>
      </router-link>
      <div class="flex">
        <vs-button radius color="warning" type="border" icon-pack="feather" icon="icon-edit-2" size="small" class="mr-1" @click="updateKategori(kategori)"></vs-button>
        <vs-button radius color="danger" type="border" icon-pack="feather" icon="icon-trash" size="small" @click="deleteKategori(kategori)"></vs-button>
      </div>
      <vs-divider class="my-2"></vs-divider>
    </div>
      
    <!-- Kategori Add Prompt -->
    <vs-prompt
    title="Add Kategori"
    accept-text= "Submit"
    button-cancel = "border"
    @cancel="clearKategori"
    @accept="submitKategori"
    @close="clearKategori"
    :is-valid="validateKategori"
    :active.sync="activePromptKategori">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-input name="title" class="w-full mb-4 mt-5" placeholder="Title" v-model="dataKategori" />
          </div>
        </div>
      </form>
    </vs-prompt>

    <!-- Kategori Add 
    <vs-popup title="Tambah Kategori" :active.sync="activeAddKategori">
      <vs-input name="title" class="w-full mb-4 mt-5" placeholder="Title" v-model="dataKategori" />
      <div class="flex flex-wrap items-center justify-end">
        <vs-button color="warning" class="mt-2" @click.stop="submitKategori()">Submit</vs-button>
      </div>
    </vs-popup>
    -->

    <!-- Kategori Edit -->
    <vs-popup title="Edit Kategori" :active.sync="activeUpdateKategori">
      <vs-input name="title" class="w-full mb-4 mt-5" placeholder="Title" v-model="dataKategori" />
      <div class="flex flex-wrap items-center justify-end">
        <vs-button color="warning" class="mt-2" @click.stop="submitKategoriUpdate()" >Update</vs-button>
      </div>
    </vs-popup>

    <!-- Kategori Hapus -->
    <vs-popup title="Hapus Kategori" :active.sync="activeDeleteKategori">
      <vs-input name="title" class="w-full mb-4 mt-5" placeholder="Title" v-model="dataKategori" disabled/>
      <div class="flex flex-wrap items-center justify-end">
        <vs-button color="danger" class="mt-2" @click.stop="submitKategoriDelete()">Delete</vs-button>
      </div>
    </vs-popup>

  </div>
</template>
  
<script>

export default{
  components: {
  },
  props: {
    menuFilter: {
      type: String,
      required: true
    },
    Search: {
      type: String,
    }
  },
  data () {
    return {
      activePromptKategori: false,
      activeAddKategori: false,
      activeUpdateKategori: false,
      activeDeleteKategori: false,
      dataIDKategori: null,
      dataKategori: null
    }
  },
  computed: {
    validateKategori () {
      return !this.errors.any() && this.dataKategori
    },
    baseUrl () {
      const path = this.$route.path
      const paths = path.slice(0, path.lastIndexOf('/'))
      return paths.slice(0, paths.lastIndexOf('/'))
    },
    menuKategori () {
      return this.$store.state.kategori.kategori
    }
  },
  methods: {
    addUpdate () {
      this.$emit('openUpdate');
      this.$emit('closeFilter');
    },
    clearKategori () {
      this.$nextTick(() => {
        this.dataIDKategori = null
        this.dataKategori = null
        this.activePromptKategori = false
        this.activeAddKategori = false
        this.activeUpdateKategori = false
        this.activeDeleteKategori = false
      })
    },
    addKategori () {
      this.activeAddKategori = true
    },
    submitKategori () {
      this.$vs.loading()
      const obj = {
        name: this.dataKategori,
        term_group: 0,
        parent: 0,
        taxonomy: 'kategori_menu'
      }

      this.$store.dispatch('menu/kategoriAdd', obj)
      .then((response) => {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Berhasil',
          color  : 'success',
          text  : response.message,
        })
      })
      .catch(error => {
        this.$vs.loading.close()
        let {data} = error.response
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      this.clearKategori()
    },
    updateKategori (kategori) {
      this.dataIDKategori = kategori.id
      this.dataKategori = kategori.name
      this.activeUpdateKategori = true
    },
    submitKategoriUpdate () {
      this.$vs.loading()
      const obj = {
        id: this.dataIDKategori,
        name: this.dataKategori,
        term_group: 0,
        parent: 0,
        taxonomy: 'kategori_menu'
      }

      this.$store.dispatch('menu/kategoriUpdate', obj)
      .then((response) => {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Berhasil',
          color  : 'success',
          text  : response.message,
        })
      })
      .catch(error => {
        this.$vs.loading.close()
        let {data} = error.response
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      this.clearKategori()
    },
    deleteKategori (kategori) {
      this.dataIDKategori = kategori.id
      this.dataKategori = kategori.name
      this.activeDeleteKategori = true
    },
    submitKategoriDelete () {
      this.$vs.loading()
      this.$store.dispatch('menu/kategoriRemove', this.dataIDKategori)
      .then((response) => {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Berhasil',
          color  : 'success',
          text  : response.message,
        })
      })
      .catch(error => {
        this.$vs.loading.close()
        let {data} = error.response
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      this.clearKategori()
    }
  },
  created () {
  }
}
</script>
<style lang="scss">
  .con-vs-popup .vs-popup {
    width: 400px;
    max-width: calc(100% - 30px);
    max-height: calc(100% - 30px);
  }
</style>
