
import axios from '@/axios.js'

export default {

  taskAll ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/task-list-all`, payload)
        .then((response) => {
          commit('TASK_SETS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  taskLayanan ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/task-list-all`, payload)
        .then((response) => {
          commit('LAYANANS_SETS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  taskPage ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/task-list-page`, payload)
        .then((response) => {
          commit('TASK_SETS', response.data.data.tasks)
          commit('TASK_SET_TOTALS', response.data.data.total)
          commit('TASK_SET_LAST', response.data.data.last_page)
          commit('TASK_SET_PER_PAGE', response.data.data.per_page)
          commit('TASK_SET_CURRENT_PAGE', response.data.data.current_page)

          //commit('KATEGORI_SET', response.data.data.kategori)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  taskPagePost ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/task-list-page`, payload)
        .then((response) => {
          commit('TASK_SETS', response.data.data.tasks)
          commit('TASK_SET_TOTALS', response.data.data.total)
          commit('TASK_SET_LAST', response.data.data.last_page)
          commit('TASK_SET_PER_PAGE', response.data.data.per_page)
          commit('TASK_SET_CURRENT_PAGE', response.data.data.current_page)

          //commit('KATEGORI_SET', response.data.data.kategori)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  taskPagePostJoinStatus ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/task-list-page-join-status`, payload)
        .then((response) => {
          commit('TASK_SETS', response.data.data.tasks)
          commit('TASK_SET_TOTALS', response.data.data.total)
          commit('TASK_SET_LAST', response.data.data.last_page)
          commit('TASK_SET_PER_PAGE', response.data.data.per_page)
          commit('TASK_SET_CURRENT_PAGE', response.data.data.current_page)

          //commit('KATEGORI_SET', response.data.data.kategori)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  taskStore ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/task-add', data)
      .then((response) => {
        if (data.urutan === 'desc') {
          commit('TASK_ADD_DESC', response.data.data)
        } else {
          commit('TASK_ADD_ASC', response.data.data)
        }
        resolve(response.data)
      })
      .catch((error) => { reject(error) })
    })
  },

  taskUpdate ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/task-update', data)
      .then((response) => {
        commit('TASK_UPDATE', response.data.data)
        resolve(response.data)
      })
      .catch((error) => { reject(error) })
    })
  },

  taskVerifikasi ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/task-update-status', data)
      .then((response) => {
        commit('TASK_VERIFIKASI', data)
        resolve(response.data)
      })
      .catch((error) => { reject(error) })
    })
  },

  taskVerifikasiAntrian ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/task-update-status', data)
      .then((response) => {
        commit('TASK_VERIFIKASI_ANTRIAN', data)
        resolve(response.data)
      })
      .catch((error) => { reject(error) })
    })
  },

  taskDelete ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/task-delete/${id}`)
        .then((response) => {
          commit('TASK_DELETE', id)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }

}
