<template>
  <div>
    <vs-sidebar click-not-close parent="#task-app" :hidden-background="true" class="full-vs-sidebar task-view-sidebar items-no-padding" v-model="isUpdateActive" position-right>
      <div class="px-0 sm:pb-6 h-full task-area-loading">
        <div class="flex flex-wrap items-center justify-between md:px-8 px-6 pt-4 d-theme-dark-bg task-header bg-primary">
          <div class="flex mb-4">
            <div class="flex items-center">
              <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="closeUpdateView" class="cursor-pointer mr-4" svg-classes="text-white w-6 h-6"></feather-icon>
              <h5 class="text-white">{{ Object.entries(this.data).length === 0 ? "New" : "View" }} Menu</h5>
            </div>
          </div>

          <div class="ml-10 mb-4 mt-1 flex items-center" v-if="Object.entries(this.data).length != 0">
            <template v-if="filter != 'trash'">
              <vs-switch class="ml-2" color="dark" v-model="disabled" v-can="'E'"/>
              <vs-button class="ml-4" color="danger" size="small" icon-pack="feather" icon="icon-trash" @click="hapusTaskConfirm()" v-can="'D'">Trash</vs-button>
            </template>
            <template v-else>
              <vs-button class="ml-4" color="warning" size="small" icon-pack="feather" icon="icon-refresh-ccw" @click="restoreTaskConfirm()" v-can="'D'">Restore</vs-button>
            </template>
          </div>
        </div>

        <component :is="scrollbarTag" class="task-content-scroll-area md:px-8 pt-4 px-6 mt-base" :settings="settings" :key="$vs.rtl">
          <div class="vx-row">
            <div class="vx-col w-full sm:w-3/4 sm:px-5 items-center mx-auto 
            border border-l-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light">
              
              <div class="vx-row mb-4">
                <div class="vx-col w-1/2">
                  <vs-input label="Title" v-model="dataTitle" class="w-full" name="title" v-validate="'required'" :disabled="disabled"/>
                  <span class="text-danger text-sm" v-show="errors.has('title')">{{ errors.first('title') }}</span>
                </div>
                <div class="vx-col w-1/2">
                  <vs-input label="URL" v-model="dataURL" class="w-full" name="url" v-validate="'required'" :disabled="disabled"/>
                  <span class="text-danger text-sm" v-show="errors.has('url')">{{ errors.first('url') }}</span>
                </div>
              </div>
              
              <div class="vx-row mb-6">
                <div class="vx-col w-1/2">
                  <vs-select v-model="dataGroup" label="Group" class="w-full" name="group" :disabled="disabled">
                    <vs-select-item value="0" text="Tidak ada" />
                    <vs-select-item :key="item.id" :value="item.id" :text="item.title" v-for="item in taskList" />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('group')">{{ errors.first('group') }}</span>
                </div>
                <div class="vx-col w-1/2">
                  <vs-input label="Icon" v-model="dataIcon" class="w-full" name="icon" :disabled="disabled"/>
                </div>
              </div>
          
              <div class="vx-row mb-6">
                <div class="vx-col w-1/2">
                  <vs-input label="Position" v-model="dataPosition" class="w-full" name="position" :disabled="disabled"/>
                </div>
                <div class="vx-col w-1/2">
                  <vs-input label="Target" v-model="dataTarget" class="w-full" name="target" :disabled="disabled"/>
                </div>
              </div>
          
              <div class="vx-row mb-6">
                <div class="vx-col w-1/2">
                  <vs-input label="Classes" v-model="dataClasses" class="w-full" name="classes" :disabled="disabled"/>
                </div>
                <div class="vx-col w-1/2">
                  <vs-input label="XFN" v-model="dataXFN" class="w-full" name="XFN" :disabled="disabled"/>
                </div>
              </div>

            </div>

            <div class="vx-col w-full sm:w-1/4 items-center mx-auto sm:px-5">
              <!-- Upload Gambar
              <div class="vx-row">
                <div class="vx-col w-full">
                    <template v-if="Attachment">
                      <div class="img-container w-full" v-if="upImg">
                        <img :src="getImage('/task/'+Attachment)" alt="" class="responsive" v-if="upImg">
                      </div>
                      <div class="img-container w-full">
                        <img :src="Attachment" alt="" class="responsive">
                      </div>
                      <div class="modify-img flex justify-between">
                        <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
                        <vs-button class="w-full" @click="$refs.updateImgInput.click();upImg = false" color="warning" :disabled="disabled">Update Attachment (Image)</vs-button>
                      </div>
                    </template>

                    <div class="upload-img" v-else>
                      <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
                      <vs-button class="w-full" @click="$refs.uploadImgInput.click();upImg = false" color="warning" :disabled="disabled">Upload Attachment (Image)</vs-button>
                    </div>
                </div>
              </div>
              -->

              <!--
              <vs-table ref="table" multiple v-model="dataKategori" search :data="menuKategori">
                <template slot="thead">
                  <vs-th>Kategori</vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="tr.slug" v-for="tr in data">
                      <vs-td>
                        <p>{{ tr.name }}</p>
                      </vs-td>
                    </vs-tr>
                </template>
              </vs-table>
              -->

              <vs-table ref="table" search :data="menuKategori">
                <template slot="thead">
                  <vs-th></vs-th>
                  <vs-th>Kategori</vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="tr.slug" v-for="tr in data">
                      <vs-td class="px-0"> <vs-checkbox v-model="dataKategori" :vs-value="tr.taxonomy_id" :disabled="disabled"></vs-checkbox> </vs-td>
                      <vs-td class="px-0"> <p>{{ tr.name }}</p> </vs-td>
                    </vs-tr>
                </template>
              </vs-table>

              <div class="vx-row" v-if="!disabled">
                <vs-divider></vs-divider>
                <div class="vx-col w-full">
                  <!--
                  <vs-button type="border" color="danger" @click="closeUpdateView" class="w-full">Cancel</vs-button>
                  -->

                  <vs-button :disabled="!isFormValid" @click="submitData" color="primary" class="mt-4 w-full">Submit</vs-button>
                </div>
              </div>
            </div>
          </div>
        </component>
      </div>

      <vs-prompt
        color="danger"
        title="Konfirmasi"
        @accept="hapusTaskAccept"
        :active.sync="hapusTask">
        <div class="con-exemple-prompt">
          Yakin ingin menghapus menu {{ dataTitle }}
        </div>
      </vs-prompt>

      <vs-prompt
        color="warning"
        title="Konfirmasi"
        @accept="restoreTaskAccept"
        :active.sync="restoreTask">
        <div class="con-exemple-prompt">
          Yakin ingin me-restore menu {{ dataTitle }}
        </div>
      </vs-prompt>
    </vs-sidebar>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import VuePerfectScrollbar  from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'
import { CurrencyDirective } from 'vue-currency-input'
export default{
  props: {
    isUpdateActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      // Set
        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: 0.50
        },
        disabled: false,

      // Init
        // Task
        dataTaskID: 0,
        dataTitle: null,
        dataStatus: 'publish',
        dataCommentStatus: 'closed',
        dataPassword: null,
        dataParent: 0,
        dataType: 'menu',

        // Meta
        dataGroup: 0,
        dataURL: null,
        dataOrder: null,
        dataHeader: null,
        dataIcon: null,
        dataPosition: null,
        dataTarget: null,
        dataClasses: null,
        dataXFN: null,

        // Relation
        dataKategori: [],
      
      Attachment : null,
      upImg: true,
      hapusTask: false,
      restoreTask: false
    }
  },
  components: {
    Datepicker,
    VuePerfectScrollbar
  },
  directives: {
    currency: CurrencyDirective
  },
  watch: {
    isUpdateActive (value) {
      if (value) {
        if (Object.entries(this.data).length === 0) {
            this.disabled = false
            this.initValues()
            this.$validator.reset()
        } else {
          this.disabled = true
          const { id, task_title, task_status, comment_status, task_password, task_type, task_parent, group, url, order, header, icon, position, target, classes, XFN, relation} = JSON.parse(JSON.stringify(this.data))
          // tasks_id, 

          // Task
          this.dataTaskID = id
          this.dataTitle = task_title
          this.dataStatus = task_status
          this.dataCommentStatus = comment_status
          this.dataPassword = task_password
          this.dataType = task_type
          this.dataParent = task_parent

          // Meta
          this.dataGroup = group
          this.dataURL = url
          this.dataOrder = order
          this.dataHeader = header
          this.dataIcon = icon
          this.dataPosition = position
          this.dataTarget = target
          this.dataClasses = classes
          this.dataXFN = XFN

          // Const
          this.Attachment = null

          // Relation
          const kat = relation.kategori_menu
          for (const el of kat) {
            this.dataKategori.push(el.taxonomy_id)
          }
          
          //this.dataKategori.push(relation.kategori_menu)

          /*
          {
            "id": 8,
            "tasks_id": 8,
            "task_author": 1,
            "task_title": "Dashboard",
            "task_status": "publish",
            "comment_status": "closed",
            "task_password": "null",
            "task_parent": 0,
            "task_name": "dashboard",
            "task_type": "menu",
            "comment_count": 0,
            "group": "0",
            "url": "/",
            "order": null,
            "header": null,
            "icon": "SlidersIcon",
            "position": "-",
            "target": "-",
            "classes": "-",
            "XFN": "-",
            "relation": {
              "role": [
                {
                  "id": 1,
                  "name": "Admininistrator",
                  "slug": "admininistrator",
                  "term_group": 0
                }
              ],
              "nav_menu": [
                {
                  "id": 2,
                  "name": "Admin Menu",
                  "slug": "admin-menu",
                  "term_group": 0
                }
              ],
              "kategori-menu": [
                {
                  "id": 16,
                  "name": "Desktop Left",
                  "slug": "desktop-left",
                  "term_group": 0
                }
              ]
            }
          }
          */

        }
      } else {return}
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
    }),
    isFormValid () { 
      return !this.errors.any() && !this.disabled && this.dataTitle
    },
    filter () {
      return this.$route.params.filter
    },
    taskList () { return this.$store.state.menu.tasks },
    menuKategori () { return this.$store.state.kategori.kategori }
  },
  created () {
  },
  methods: {
    submitData () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          let formData = new FormData()

          formData.set('title', this.dataTitle)
          formData.set('status', this.dataStatus)
          formData.set('comment_status', this.dataCommentStatus)
          formData.set('password', this.dataPassword)
          formData.set('parent', 0)
          formData.set('type', this.dataType)
          formData.set('comment_count', 0)

          let meta = {
            group: this.dataGroup,
            url: this.dataURL,
            order: this.dataOrder,
            header: this.dataHeader,
            icon: this.dataIcon,
            position: this.dataPosition,
            target: this.dataTarget,
            classes: this.dataClasses,
            XFN: this.dataXFN
          }
          formData.set('meta', JSON.stringify(meta))

          /*
          formData.set('attachment_upload', this.upImg)
          if (this.upImg === false) { 
            formData.append('attachment_file', this.Attachment)
          } else {
            formData.set('attachment_file', this.Attachment)
          }
          */

          let relation =  {
            'kategori_menu': this.dataKategori
          }
          formData.set('relation', JSON.stringify(relation))

          formData.set('urutan', 'desc')
          if (this.dataTaskID === 0) {
            this.$store.dispatch('menu/taskStore', formData)
            .then((response) => {
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Berhasil',
                color  : 'success',
                text  : response.message,
              })
              this.initValues()
              this.closeUpdateView()
              this.initValues()
            })
            .catch(error => {
              this.$vs.loading.close()
              let {data} = error.response
              this.$vs.notify({
                title: 'Error',
                text: data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
          } else {
            formData.set('id', this.dataTaskID)
            this.$store.dispatch('menu/taskUpdate', formData)
            .then((response) => {
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Berhasil',
                color  : 'success',
                text  : response.message,
              })
              this.initValues()
              this.closeUpdateView()
              this.initValues()
            })
            .catch(error => {
              this.$vs.loading.close()
              let {data} = error.response
              this.$vs.notify({
                title: 'Error',
                text: data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
          }
        }
      })
    },
    hapusTaskConfirm () {
      this.hapusTask = true
    },
    hapusTaskAccept () {
      if (Object.entries(this.data).length > 0) {
        this.$vs.loading()
        this.$store.dispatch('menu/removeTask', this.data)
        .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : response.message,
          })
          this.initValues()
          this.closeUpdateView()
          this.initValues()
        })
      }
      this.hapusTask = false
    },
    restoreTaskConfirm () {
      this.restoreTask = true
    },
    restoreTaskAccept () {
      if (Object.entries(this.data).length > 0) {
        this.$vs.loading()
        this.$store.dispatch('menu/restoreTask', this.data)
        .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : response.message,
          })
          this.initValues()
          this.closeUpdateView()
          this.initValues()
        })
      }
      this.restoreTask = false
    },
    initValues() {
      // Task
      this.dataTaskID = 0
      this.dataTitle = null
      this.dataStatus = 'publish'
      this.dataCommentStatus = 'closed'
      this.dataPassword = null
      this.dataParent = 0
      this.dataType = 'menu'

      // Meta
      this.dataGroup = 0
      this.dataURL = null
      this.dataOrder = null
      this.dataHeader = null
      this.dataIcon = null
      this.dataPosition = null
      this.dataTarget = null
      this.dataClasses = null
      this.dataXFN = null

      this.dataKategori = []

      // Const
      this.Attachment = null
      this.upImg = true
      this.disabled = false 
      this.hapusTask = false
      this.restoreTask = false
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.Attachment = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    //
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    },
    closeUpdateView() {
      this.initValues()
      this.$emit('closeUpdate')
      this.initValues()
    }
  }
}
</script>
<style lang="scss" scoped>
.task-view-sidebar {
  ::v-deep .vs-sidebar {
    .vs-table--search {
      margin-left: 0;
      max-width: 100%;
      width: 100%;
    }
    .vs-con-table .vs-con-tbody {
      background: none;
      border: none;
      height: 200px;
    }
    .vs-table--search-input {
      width: 100%;
    }
    .vs-table--tbody-table {
      min-width: 0;
    }
  }
}
</style>