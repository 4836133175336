
import axios from '@/axios.js'

export default {

  kategoriFetch ({ commit }, tipe) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/${tipe}`)
        .then((response) => {
          commit('KATEGORI_SET', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  kategoriAdd ({ commit }, tipe) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', tipe)
        .then((response) => {
          commit('KATEGORI_ADD', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  kategoriUpdate ({ commit }, kategori) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${kategori.id}`, kategori)
        .then((response) => {
          commit('KATEGORI_UPDATE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  kategoriUpdateForm ({ commit }, kategori) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update-form`, kategori)
        .then((response) => {
          commit('KATEGORI_UPDATE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  kategoriRemove ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${id}`)
        .then((response) => {
          commit('KATEGORI_REMOVE', id)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }

}
