
export default {
  // TASK
    TASK_SETS (state, task) {
      state.tasks = task
    },
    TASK_SET_TOTALS (state, payload) {
      state.tasks_total = payload
    },
    TASK_SET_LAST (state, payload) {
      state.tasks_last = payload
    },
    TASK_SET_PER_PAGE (state, payload) {
      state.tasks_per_page = payload
    },
    TASK_SET_CURRENT_PAGE (state, payload) {
      state.tasks_current_page = payload
    },
    TASK_SET_NULL (state) {
      state.tasks = []
    },

    TASK_SET_CURRENT (state, current) {
      state.task = current
    },

    TASK_ADD_DESC (state, item) {
      state.tasks.unshift(item)
    },
    TASK_ADD_ASC (state, item) {
      state.tasks.push(item)
    },

    TASK_UPDATE (state, item) {
      const taskIndex = state.tasks.findIndex((p) => p.id === item.id)
      Object.assign(state.tasks[taskIndex], item)
    },

    TASK_VERIFIKASI (state, item) {
      let object = {};
      item.forEach(function(value, key){ object[key] = value;});
      const taskIndex = state.tasks.findIndex((p) => p.id == object.id)
      const alurIndex = state.tasks[taskIndex].relation.alur.findIndex((alur) => alur.id === parseInt(object.role))
      state.tasks[taskIndex].relation.alur[alurIndex].object_status = 'true'
    },

    TASK_VERIFIKASI_ANTRIAN (state, item) {
      let object = {};
      item.forEach(function(value, key){ object[key] = value;});
      const taskIndex = state.tasks.findIndex((p) => p.id == object.antrian_id)
      const alurIndex = state.tasks[taskIndex].parent.relation.alur.findIndex((alur) => alur.id === parseInt(object.role))
      state.tasks[taskIndex].parent.relation.alur[alurIndex].object_status = 'true'
    },

    TASK_DELETE (state, Id) {
      const Index = state.tasks.findIndex((p) => p.id === Id)
      state.tasks.splice(Index, 1)
    },
  //
  // LAYANAN
    LAYANANS_SETS (state, task) {
      state.layanans = task
    },

}
